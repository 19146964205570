export default {
  enums: {
    EmailType: {
      PRIVATE: 'private',
      BUSINESS: 'business',
      OTHER: 'other',
    },
    PhoneNumberType: {
      PRIVATE: 'private',
      BUSINESS: 'business',
      PRIVATE_MOBILE: 'private mobile',
      BUSINESS_MOBILE: 'business mobile',
      FAX: 'Fax',
      OTHER: 'other',
    },
  },
  contactform: {
    company: {
      label: 'Company',
    },
    firstName: {
      label: 'First Name',
    },
    lastName: {
      label: 'Last Name',
    },
    street: {
      label: 'Street, Number',
    },
    zipCode: {
      label: 'Zip Code',
    },
    city: {
      label: 'City',
    },
    country: {
      label: 'Country',
    },
    emails: {
      title: 'E-mail Addresses',
      itemTitleUnknown: 'New E-mail Address',
      noData: 'No e-mail addresses defined',
      emailType: {
        label: 'Type',
      },
      email: {
        label: 'E-mail Address',
      },
    },
    phoneNumbers: {
      title: 'Telephone Numbers',
      itemTitleUnknown: 'New Telephone Number',
      noData: 'No telephone numbers defined',
      phoneNumberType: {
        label: 'Type',
      },
      phoneNumber: {
        label: 'Number',
      },
    },
  },
};
