export default {
  title: 'Administrators',
  list: {
    header: {
      name: 'Name',
      email: 'E-mail',
    },
    delete: {
      title: 'Delete Administrator',
      message: 'Do you really want to delete the user {fullName}?',
    },
  },
  form: {
    titleNew: 'New Administrator',
    firstName: {
      label: 'First Name',
    },
    lastName: {
      label: 'Last Name',
    },
    email: {
      label: 'E-mail Address',
    },
    isLocked: {
      label: 'user is blocked',
    },
    sendActivationEmail: {
      label: 'sende activation-mail',
    },
  },
};
