export default {
  dialog: {
    setStateAdmin: {
      title: 'Set State - ADMIN-ACTION!',
      text: 'Please choose your target state.',
      close: 'Close',
    },
  },
  enums: {
    ReceiverOption: {
      CONTACT_ADDRESS: 'Contact Address',
      COST_CENTER: 'Cost Center',
      ALTERNATIVE_ADDRESS: 'User defined Address',
    },
    ContractState: {
      CREATED: 'Created',
      TRANSLATOR_ASSIGNED: 'Translator assigned',
      TRANSLATOR_CONFIRMED: 'Translator confirmed',
      CONTRACT_FULFILLED: 'Fulfilled',
      CANCELED: 'Canceled',
    },
    InvoiceFilter: {
      INVOICE_CREATED: 'Invoice created',
      INVOICE_NOT_CREATED: 'Invoice not created',
    },
    CreditFilter: {
      CREDIT_CREATED: 'Credit created',
      CREDIT_NOT_CREATED: 'Credit not created',
    },
  },
  list: {
    setStateAdmin: {
      label: 'set state (ADMIN)',
    },
  },
  form: {
    businessUnit: {
      label: 'Business Unit',
    },
    customer: {
      label: 'Customer',
    },
    contractDate: {
      label: 'Contract Date',
    },
    customerLanguage: {
      label: 'Source Language',
    },
    translatorLanguage: {
      label: 'Target Language',
    },
    invoiceReceiverOption: {
      label: 'Invoice Address of Customer',
    },
    creditReceiverOption: {
      label: 'Credit Address of Translator',
    },
    afterFulfillmentText: {
      label: 'To complete after Contract fulfillment.',
    },
  },
};
