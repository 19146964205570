export default {
  title: 'Cost Centers',
  list: {
    header: {
      name: 'Cost Center',
    },
    delete: {
      title: 'Delete Cost Center',
      message: 'Do you really want to delete the cost center {name}?',
    },
  },
  form: {
    titleNew: 'New Cost Center',
    name: {
      label: 'Name',
    },
  },
  invoiceContact: {
    title: 'Invoice Contact',
  },
};
