export default {
  title: 'Account Sales',
  usageDialog: {
    usage: 'Purpose',
    close: 'close',
  },
  list: {
    header: {
      btIdentity: 'Transaction-ID',
      btValue: 'Revenue',
      btValueAssigned: 'Document Sum',
      amountOfBalance: 'Balance',
      btUsage: 'Purpose',
      transactionDate: 'Transaction date',
      businessUnit: 'Business Unit',
      bankAccount: 'Bank Account',
      bankAccountTransactionAssignmentState: 'State of Assignment',
    },
    edit: {
      acceptIncompleteAssignment: 'Accept incomplete assignments',
    },
    filter: {
      businessUnit: {
        label: 'Business Unit',
      },
      bankAccount: {
        label: 'Bank Account must contain',
      },
      btValueFrom: {
        label: 'Revenue larger than',
      },
      btValueTo: {
        label: 'Revenue less than',
      },
      BankAccountTransactionAssignmentState: {
        label: 'State of Assignment',
      },
      btUsage: {
        label: 'Purpose must contain',
      },
      btIdentity: {
        label: 'Transaction ID',
      },
      transactionDateFrom: {
        label: 'Transaction Date after',
      },
      transactionDateUntil: {
        label: 'Transaction Date before',
      },
      invoiceId: {
        label: 'Invoice',
      },
      creditId: {
        label: 'Credit',
      },
      externalDocumentId: {
        label: 'External document',
      },
    },
  },
  form: {
    btValue: {
      label: 'Revenue',
    },
    btUsage: {
      label: 'Purpose',
    },
    transactionDate: {
      label: 'Transaction date',
    },
    businessUnit: {
      label: 'Business unit',
    },
    bankAccountTransactionInvoicePositions: {
      label: 'Assignments - Invoices',
    },
    bankAccountTransactionCreditPositions: {
      label: 'Assignments - Credits',
    },
    bankAccountTransactionExternalDocumentPositions: {
      label: 'Assignments - External documents',
    },
    notAllBusinessUnitAssignmentsValid: 'Warning: not all assigned evidences belong to the selected bankaccount of the businessunit {0}.',
    createExternalDocument: {
      label: 'Create new external document',
    },
  },
  enums: {
    BankAccountTransactionAssignmentState: {
      NOT_ASSIGNED: 'not assigned',
      ASSIGNMENT_INCOMPLETE_AUTOMATIC: 'incomplete (automatic)',
      ASSIGNMENT_INCOMPLETE_MANUAL: 'incomplete',
      ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED: 'incomplete, but accepted',
      ASSIGNMENT_COMPLETE_AUTOMATIC: 'complete(automatic)',
      ASSIGNMENT_COMPLETE_MANUAL: 'complete',
    },
  },
  positionList: {
    header: {
      invoice: 'Invoice',
      credit: 'Credit',
      externalDocument: 'External document',
      businessUnit: 'Business unit',
      assignedPriceNet: 'Document value (net)',
      assignedPriceGross: 'Document value (gross)',
    },
    edit: {
      addBankTransactionPosition: {
        label: 'New assignment',
      },
      assignedPriceNet: {
        label: 'Document value (net)',
      },
      assignedPriceGross: {
        label: 'Document value (gross)',
      },
    },
  },
};
