export default {
  title: 'Customers',
  list: {
    actions: {
      sendEmailToCustomer: 'Send e-mail to customer',
    },
    header: {
      name: 'Name',
      email: 'E-mail',
      customerGroup: 'Customer Group',
      costCenter: 'Cost Center',
      customerComment: 'Comment',
    },
    filter: {
      firstName: {
        label: 'First Name',
      },
      lastName: {
        label: 'Last Name',
      },
      email: {
        label: 'E-mail Address',
      },
      customer: {
        label: 'Customer',
      },
      customerComment: {
        label: 'Comment contains',
      },
      costCenter: {
        label: 'Cost Center',
      },
      customerGroup: {
        label: 'Customer Group',
      },
    },
    delete: {
      title: 'Delete Customer',
      message: 'Do you really want to delete the user {fullName}?',
    },
    edit: {
      sendEmail: {
        title: 'send Email',
      },
    },
  },
  form: {
    titleNew: 'New Customer',
    firstName: {
      label: 'First Name',
    },
    lastName: {
      label: 'Last Name',
    },
    email: {
      label: 'E-mail Address',
    },
    taxId: {
      label: 'Tax-ID',
    },
    vatExempt: {
      label: 'VAT-exempt',
    },
    customerGroup: {
      label: 'Customer Group',
    },
    costCenter: {
      label: 'Cost Center',
    },
    customerComment: {
      label: 'Comment',
    },
    isLocked: {
      label: 'User is locked',
    },
    sendActivationEmail: {
      label: 'Send activation e-mail',
    },
  },
  commentDialog: {
    comment: 'Comment',
    close: 'Close',
  },
    languageGroups: {
    title: 'Language Groups',
    noData: 'No language groups defined',
    name: {
      label: 'Name',
      error: {
        customerLanguageGroupsNameDuplicates: 'Language groups must have unique names.',
      },
    },
    languages: {
      label: 'Languages',
      error: {
        customerLanguageGroupsLanguagesDuplicates: 'A language must not be used in multiple language groups.',
      },
    },
  },
  costConfiguration: {
    title: 'Cost Configuration',
    fixedRateNet: {
      label: 'Fixed Rate (net)',
    },
    costConfigurationCycles: {
      label: 'Billing Cycles',
      titleUnknown: 'New Billing Cycle',
      noData: 'No billing cycles defined',
      customerCostConfigurationCycleDuplicates: 'Attention: Combination of customer groups and billing unit is not unique.',
      costConfigurationCycle: {
        customerLanguageGroup: {
          label: 'Language Group',
        },
        costConfigurationCycleType: {
          label: 'Billing Unit',
        },
        firstCycleDuration: {
          label: 'First Cycle',
        },
        firstCycleCostNet: {
          label: 'First Cycle Amount (net)',
        },
        followingCycleDuration: {
          label: 'Following Cycles',
        },
        followingCycleCostNet: {
          label: 'Amount per Following Cycle (net)',
        },
      },
    },
  },
  invoiceContact: {
    title: 'Invoice Contact',
  },
  email: {
    title: 'Send E-Mail',
    to: {
      label: 'To',
    },
    cc: {
      label: 'Copy to',
    },
    subject: {
      label: 'Subject',
    },
    body: {
      label: 'Content',
    },
    send: {
      label: 'Send',
    },
  },
  fileform: {
    title: 'File attachments',
    noData: 'No File attachments',
    filename: 'filename',
    uploadRequest: 'Please upload your attachment.',
    file: 'File',
  },
};
