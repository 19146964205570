export default {
  title: 'Calendar',

  type: {
    'month': 'Month',
    'week': 'Week',
    'day': 'Day',
    '4day': '4 days',
  },

  header: {
    'month': '{startMonth} {startYear}',
    'week': '{startMonth} {startDay} {startYear} - {endMonth} {endDay} {suffixYear}',
    'day': '{startDay} {startMonth} {startYear}',
    '4day': '{startMonth} {startDay} {startYear} - {endMonth} {endDay} {suffixYear}',
  },

  filter: {
    businessUnit: 'Business unit',
    language: 'Language',
    translator: 'Translator',
    customer: 'Customer',
    calendarObjectTypes: 'Type of Entry',
  },

  today: {
    label: 'Today',
  },

  navigate: {
    label: 'Open',
  },

  appointmentCommentDialog: {
    content: 'Description of the Entry',
    close: 'close',
  },

  hourOverviewDialog: {
    title: 'Overview of hour',
    close: 'close',
  },

  enums: {
    CalendarObjectTypes: {
      ON_SITE_CONTRACT: 'Contract - On site translation',
      DOCUMENT_CONTRACT: 'Contract - Document translation',
      VIDEO_CONTRACT: 'Contract - Video translation',
      INVOICE: 'Invoices',
      TASK: 'Tasks',
    },
  },
};
