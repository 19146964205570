import base from '../../locale/en';
import _ from 'lodash';

export default _.merge(_.cloneDeep(base), {
  title: 'On Site Translations',
  usageDialog: {
    usage: 'Comment',
    close: 'close',
  },
  warningDialog: {
    usage: 'Hint',
    close: 'close',
    creditAlreadyExists: 'For selected contracts exists at least one credit.',
    createCreditForced: 'create additional credit',
    invoiceAlreadyExists: 'For selected contracts exists at least one invoice.',
    createInvoiceForced: 'create additional invoice',
    hasMoreThanOneTranslator: 'For creation of credits are selection with only translator permitted. Please change your selection of contracts.',
    hasMoreThanOneCustomer: 'For creation of invoices are selection with only customer permitted. Please change your selection of contracts.',
    isCreditAlreadyCreated: 'For at least one contract of your selected contracts exists a already a credit.',
    isInvoiceAlreadyCreated: 'For at least one contract of your selected contracts exists a already a invoice.',
  },
  enums: {
    TravelOption: {
      FIX_RATE: 'Fixed Rate',
      COST_PER_KM: 'Distance flat rate per km',
      PUBLIC_TRANSIT_TICKET: 'Refund of public transport tickets',
      NO_REFUND: 'No Refund',
    },
  },
  list: {
    header: {
      contractNo: 'Contract Number',
      contractState: 'State',
      businessUnit: 'Business Unit',
      customer: 'Customer',
      translator: 'Translator',
      translatorLanguage: 'Language',
      contractDate: 'Contract Date',
      beginDateTime: 'Day of delivery',
      contractComment: 'Comment',
      operationContact: 'Operation Contact',
    },
    filter: {
      contractNo: {
        label: 'Contract Number',
      },
      contractState: {
        label: 'State',
      },
      contractComment: {
        label: 'Comment contains',
      },
      businessUnit: {
        label: 'Business Unit',
      },
      customer: {
        label: 'Customer',
      },
      translator: {
        label: 'Translator',
      },
      contractDateFrom: {
        label: 'Contract Date from',
      },
      contractDateUntil: {
        label: 'Contract Date until',
      },
      beginDateTimeFrom: {
        label: 'Date of delivery from',
      },
      beginDateTimeUntil: {
        label: 'Date of delivery until',
      },
      invoiceFilter: {
        label: 'Invoice',
      },
      creditFilter: {
        label: 'Credit',
      },
      language: {
        label: 'Language',
      },
      invoiceId: {
        label: 'Invoice',
      },
      creditId: {
        label: 'Credit',
      },
      operationContactFilter: {
        label: 'Operation Contact contains',
      },
    },
    invoiceCreated: 'Invoice created',
    creditCreated: 'Credit created',
    invoiceAndCreditCreated: 'Invoice & Credit created',
    edit: {
      contractState: {
        fulfill: 'Contract fulfilled',
        cancel: 'Cancel Contract',
        confirmCancel: {
          title: 'Cancel Contract',
          message: 'Do you really want to cancel the contract {contractNo} now?',
          yes: {
            label: 'Cancel Contract',
          },
        },
      },
      sendEmail: {
        title: 'Send E-Mail',
      },
      translator: {
        assign: 'assign',
        confirm: 'Confirm Translator',
        label: 'Select Translator',
      },
    },
    actions: {
      showInvoice: 'Show Invoice',
      showCredit: 'Show Credit',
      sendEmailToTranslator: 'Send E-Mail to translator',
      sendEmailToCustomer: 'Send E-Mail to customer',
    },
    createInvoice: {
      label: 'Create Invoice',
    },
    createCredit: {
      label: 'Create Credit',
    },
  },
  form: {
    titleNew: 'New Contract',
    beginDateTime: {
      label: 'Planned Start (date of delivery)',
    },
    plannedTranslationDuration: {
      label: 'Planned Duration in minutes',
    },
    realTranslationStart: {
      label: 'Real Start',
    },
    realTranslationEnd: {
      label: 'Real End',
    },
    travelTimeToLocationStart: {
      label: 'Start of Travel to Location',
    },
    travelTimeToLocationEnd: {
      label: 'Ende of Travel to Location',
    },
    travelTimeBackStart: {
      label: 'Start of Travel back',
    },
    travelTimeBackEnd: {
      label: 'End of Travel back',
    },
    travelDistance: {
      label: 'Travel distance for translator in km',
    },
    waitTimeAtLocation: {
      label: 'Waiting time for translator in minutes',
    },
    travelOption: {
      label: 'Travel Expense',
    },
    isCustomerTravelCost: {
      label: 'Customer Invoice',
    },
    isTranslatorTravelCost: {
      label: 'Translator Credit',
    },
    fixedTravelCost: {
      label: 'Fixed Costs in €',
    },
    travelTicketCost: {
      label: 'Ticket Costs in €',
    },
    save: {
      success: 'Changes saved for contract with the contract number {contractNo}.',
    },
    contractComment: {
      label: 'Comment',
    },
    afterFulfillmentText: {
      label: 'To complete after Contract fulfillment.',
    },
    createCustomer: {
      label: 'Create Customer',
    },
  },
  customerCommentDialog: {
    content: 'Comment - Customer',
    close: 'Close',
  },
  translatorCommentDialog: {
    content: 'Comment - Translator',
    close: 'Close',
  },
  fileform: {
    title: 'File attachments',
    noData: 'No File attachments',
    filename: 'Filename',
    uploadRequest: 'Please upload your attachment.',
    file: 'File',
  },
  fileAttachmentCollection: {
    title: 'Operation Documents',
  },
  file: {
    title: 'Files',
  },
  operationContact: {
    title: 'Operation Contact',
  },
  invoiceContact: {
    title: 'Invoice Contact',
  },
  creditContact: {
    title: 'Credit Contact',
  },
  email: {
    title: 'Send E-Mail',
    to: {
      label: 'to',
    },
    cc: {
      label: 'Copy to',
    },
    subject: {
      label: 'Subject',
    },
    body: {
      label: 'Content',
    },
    send: {
      label: 'Send',
    },
  },
  event: {
    details: {
      contractState: 'State',
      businessUnit: 'Business Unit',
      customer: 'Customer',
      translator: 'Translator',
      contractDate: 'Contract Date',
      beginDateTime: 'planned start',
    },
  },
  validate: {
    error: {
      validateStartEndExistsForStart: 'End must be input if Start exists',
      validateStartEndExistsForEnd: 'Start must be input if End exists',
      validateStartBeforeEnd: 'Start must be before End',
      validateEndAfterStart: 'End must be later after Start',
    },
  },
});
