export default {
  title: 'System settings',
  list: {
    header: {
      key: 'Property',
    },
  },
  form: {
    value: {
      label: '',
    },
  },
  keys: {
    INITIAL_LANGUAGE_GROUPS: 'Initial language groups',
    REPORT_DISTRICT_COURT: 'Report: District Court',
    REPORT_TAX_NUMBER: 'Report: Tax number',
    REPORT_TAX_OFFICE: 'Report: Tax office',
    REPORT_HOMEPAGE_URL: 'Report: Homepage',
    REPORT_HEADQUARTER: 'Report: Headquarter',
    REPORT_MANAGER: 'Report: Manager',
    REPORT_HOTLINE_24H: 'Report: 24-Hour-Hotline',
    EMAIL_SEND_ENABLED: 'E-mail sending activated',
    EMAIL_SEND_ENABLED_FOR_AUTOMATIC_ACTIVATION: 'Automatic sending of an activation email when a user is created',
    EMAIL_LOGIN: 'System e-mail: E-mail-address',
    EMAIL_PASSWORD: 'System e-mail: Password',
    EMAIL_SERVER_HOST: 'System e-mail: Host',
    EMAIL_SMTP_PORT: 'System e-mail: Port',
    PDF_LOGO: 'PDF Logo',
    SALES_TAX_ID: 'Sales tax id',
    WEBDAV_URL: 'WebDAV: Server URL',
    WEBDAV_USERNAME: 'WebDAV: Server username',
    WEBDAV_PASSWORD: 'WebDAV: Server password',
    BASE_URL: 'Base URL',
    DEFAULT_CUSTOMER_LANGUAGE_FOR_CONTRACTS: 'Default source language of contracts',
  },
};
