export default {
  title: 'Petty Cash',
  currentBalance: 'current balance',
  usageDialog: {
    usage: 'Purpose',
    close: 'close',
  },
  list: {
    delete: {
      title: 'Delete entry',
      message: 'Should the cashaccount entry really be deleted?',
    },
    header: {
      ctValue: 'Revenue',
      ctValueAssigned: 'Document Sum',
      ctUsage: 'Purpose',
      transactionDate: 'Transaction-Date',
      businessUnit: 'Business Unit',
      cashAccountTransactionAssignmentState: 'Assignment State',
    },
    edit: {
      acceptIncompleteAssignment: 'Accept incomplete assignments',
    },
    filter: {
      businessUnit: {
        label: 'Business Unit',
      },
      ctValueFrom: {
        label: 'Revenue larger than',
      },
      ctValueTo: {
        label: 'Revenue less than',
      },
      ctUsage: {
        label: 'Purpose must contain',
      },
      transactionDateFrom: {
        label: 'Transaction Date after',
      },
      transactionDateUntil: {
        label: 'Transaction Date before',
      },
      invoiceId: {
        label: 'Invoice',
      },
      creditId: {
        label: 'Credit',
      },
      externalDocumentId: {
        label: 'External document',
      },
    },
  },
  form: {
    titleNew: 'New Entry',
    ctValue: {
      label: 'Revenue',
    },
    ctUsage: {
      label: 'Purpose',
    },
    transactionDate: {
      label: 'Transaction Date',
    },
    businessUnit: {
      label: 'Business Unit',
    },
    cashAccountTransactionInvoicePositions: {
      label: 'Assignments - Invoice',
    },
    cashAccountTransactionCreditPositions: {
      label: 'Assignments - Credit',
    },
    cashAccountTransactionExternalDocumentPositions: {
      label: 'Assignments - External document',
    },
    notAllBusinessUnitAssignmentsValid: 'Warning: Not all assigned receipts belong to the selected cashaccount of the business unit {0}.',
    createExternalDocument: {
      label: 'Create new external document',
    },
  },
  enums: {
    CashAccountTransactionAssignmentState: {
      NOT_ASSIGNED: 'not assigned',
      ASSIGNMENT_INCOMPLETE_MANUAL: 'incomplete',
      ASSIGNMENT_INCOMPLETE_MANUAL_AND_ACCEPTED: 'incomplete, but accepted',
      ASSIGNMENT_COMPLETE_MANUAL: 'complete',
    },
    notAllBusinessUnitAssignmentsValid: 'Warning: not all assigned evidences belong to the selected cashaccount of the businessunit {0}.',
  },
  positionList: {
    header: {
      invoice: 'Invoice',
      credit: 'Credit',
      externalDocument: 'External document',
      businessUnit: 'Business unit',
      assignedPriceNet: 'Document value (net)',
      assignedPriceGross: 'Document value (gross)',
    },
    edit: {
      addCashTransactionPosition: {
        label: 'new Assignment',
      },
      assignedPriceNet: {
        label: 'Document value (net)',
      },
      assignedPriceGross: {
        label: 'Document value (gross)',
      },
    },
  },
};
