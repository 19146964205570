export default {
  title: 'Vat-Configuration',
  list: {
    header: {
      validFrom: 'valid from date',
      vat: 'Vat',
      vatRate: 'Vat Rate',
    },
    delete: {
      title: 'Delete Vat-Configuration',
      message: 'Should the Vat-Configuration really be deleted?',
    },
    filter: {
      vat: 'Vat',
    },
  },
  form: {
    validFrom: 'valid from date',
    vat: 'Vat',
    vatRate: 'Vat Rate',
  },
  enums: {
    Vat: {
      ZERO: 'zero',
      REDUCED: 'reduced',
      FULL: 'full',
    },
  },
};
