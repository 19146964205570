import base from '../../../locale/en';
import _ from 'lodash';

export default {
  fileform: {
    title: 'File attachments',
    noData: 'No File attachments',
    filename: 'filename',
    uploadRequest: 'Please upload your attachment.',
    file: 'File',
  },
  send: {
    ok: 'Email successfully sent',
    failed: 'Email could not be sent.',
  },
  error: {
    toBig: 'Probably emails larger than 7 MB can be not send.',
  },
};
