export default {
  title: 'E-mail Templates',
  fileAttachmentCollection: {
    title: 'File attachments',
  },
  enums: {
    EmailTemplateType: {
      CONTRACT_DOCUMENT: 'Document Contract',
      CONTRACT_ON_SITE: 'On-site Contract',
      CONTRACT_VIDEO: 'Video Contract',
      TRANSLATOR: 'Translator',
      CUSTOMER: 'Customer',
      INVOICE: 'Invoice',
      CREDIT: 'Credit',
    },
    EmailTemplatePlaceholder: {
      ADDRESS_NAME: 'Address name',
      ADDRESS: 'Address',
      BUSINESS_UNIT_NAME: 'Businessunit',
      COMMENT: 'comment',
      COMPLETE_UNTIL: 'Date: Complete until',
      CONTRACT_NO: 'Contract number',
      COST_CENTER_NAME: 'Cost center',
      CREDIT_ACCOUNTING_PERIOD_FROM: 'Credit: Accounting from',
      CREDIT_ACCOUNTING_PERIOD_UNTIL: 'Credit: Accounting till',
      CREDIT_BANK_ACCOUNT_HOLDER: 'Credit: Bank account holder',
      CREDIT_BANK_NAME: 'Credit: Bank name',
      CREDIT_BIC: 'Credit: BIC',
      CREDIT_DATE: 'Credit: Date of Credit',
      CREDIT_DOCUMENT_VALUE_NET: 'Credit value net',
      CREDIT_IBAN: 'Credit: IBAN',
      CREDIT_NO: 'Credit number',
      CREDIT_REFERENCE: 'Credit: Reference',
      CUSTOMER_EMAIL: 'Customer: Email',
      CUSTOMER_FIRSTNAME: 'Customer: First name',
      CUSTOMER_GROUP: 'Customer: Customergroup',
      CUSTOMER_LASTNAME: 'Customer: Last name',
      CUSTOMER_NAME: 'Customer: complete name',
      CUSTOMER_TAXID: 'Customer: Tax Id',
      CUSTOMER_TRAVEL_OPTION: 'Customer: Travel option',
      DATE: 'Date',
      INVOICE_DATE: 'Invoice Date',
      INVOICE_DOCUMENT_VALUE_GROSS: 'Invoice: value gross',
      INVOICE_DOCUMENT_VALUE_NET: 'Invoice: value net',
      INVOICE_EXPLANATION: 'Invoice: Explanation',
      INVOICE_LANGUAGE: 'Invoice: Language',
      INVOICE_NO: 'Invoice number',
      INVOICE_NOTICE: 'Invoice: Notice',
      INVOICE_OPERATION_LOCATION: 'Invoice: Location of Operation',
      INVOICE_OPERATION_TIME: 'Invoice: Date of Operation',
      INVOICE_REFERENCE: 'Invoice: Reference',
      INVOICE_STATE: 'Invoice state',
      LINES_OF_ORIGINAL_DOCUMENT: 'Lines of original document',
      LINES_OF_TRANSLATED_DOCUMENT: 'Lines of translated document',
      PLANNED_TRANSLATION_DURATION: 'Planned translation duration',
      REAL_TRANSLATION_DURATION: 'Real translation duration',
      RECEIVER_CONTACT_CITY: 'Receiver: City',
      RECEIVER_CONTACT_COMPANY: 'Receiver: Company',
      RECEIVER_CONTACT_COUNTRY: 'Receiver: Country',
      RECEIVER_CONTACT_FIRST_NAME: 'Receiver: First name',
      RECEIVER_CONTACT_LAST_NAME: 'Receiver: Last name',
      RECEIVER_CONTACT_STREET: 'Receiver: Street',
      RECEIVER_CONTACT_ZIPCODE: 'Receiver: Zipcode',
      SENDER_CONTACT_CITY: 'Sender: City',
      SENDER_CONTACT_COMPANY: 'Sender: Company',
      SENDER_CONTACT_COUNTRY: 'Sender: Country',
      SENDER_CONTACT_FIRST_NAME: 'Sender: First name',
      SENDER_CONTACT_LAST_NAME: 'Sender: Last name',
      SENDER_CONTACT_STREET: 'Sender: Street',
      SENDER_CONTACT_ZIPCODE: 'Sender: Zipcode',
      TRANSLATOR_ADDRESS_NAME: 'Translator: Name',
      TRANSLATOR_ADDRESS: 'Translator: Adresse',
      TRANSLATOR_BANKACCOUNTHOLDER: 'Translator: Bank account holder',
      TRANSLATOR_BANKNAME: 'Translator: Bank name',
      TRANSLATOR_BIC: 'Translator: BIC',
      TRANSLATOR_EMAIL: 'Translator: E-Mail',
      TRANSLATOR_FIRSTNAME: 'Translator: First name',
      TRANSLATOR_IBAN: 'Translator: IBAN',
      TRANSLATOR_LASTNAME: 'Translator: Last name',
      TRANSLATOR_NAME: 'Translator: complete Name',
      TRANSLATOR_TAXID: 'Translator: Tax Id',
      TRANSLATOR_TRANSLATORCOMMENT: 'Translator: Comment',
      TRANSLATOR_TRAVEL_OPTION: 'Translator: Travel option',
      TRAVEL_DISTANCE: 'Travel distance',
      TRAVEL_TICKET_COST: 'Travel cost',
      TRAVEL_TIME_BACK: 'Travel time back',
      TRAVEL_TIME_TO_LOCATION: 'Travel time to location',
    },
  },
  list: {
    header: {
      name: 'Template',
      emailTemplateType: 'Type',
      businessUnit: 'Business Unit',
    },
    delete: {
      title: 'Delete Template',
      message: 'Do you really want to delete the e-mail template {name}?',
    },
  },
  fileform: {
    title: 'File attachments',
    noData: 'No File attachments',
    filename: 'filename',
    uploadRequest: 'Please upload your attachment.',
    file: 'File',
  },
  form: {
    titleNew: 'New E-mail Template',
    name: {
      label: 'Name',
    },
    emailTemplateType: {
      label: 'Type',
    },
    businessUnit: {
      label: 'Business Unit',
      hint: 'Select a business unit if the e-mail template has to be used for this unit only.',
    },
    subject: {
      label: 'Subject',
      insertPlaceholder: {
        tooltip: 'Insert Placeholder',
      },
    },
    body: {
      label: 'Content',
    },
  },
};
