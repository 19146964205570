export default {
  title: 'Tasks',
  enums: {
    TaskState: {
      CREATED: 'created',
      DONE: 'done',
    },
  },
  list: {
    delete: {
      title: 'Delete task',
      message: 'Should the task really be deleted?',
    },
    filter: {
      taskName: { label: 'Name contains:' },
      taskState: { label: 'State is:' },
      taskDescription: { label: 'Description contains:' },
      taskDateFrom: { label: 'Due date from:' },
      taskDateTo: { label: 'Due date till:' },
      assignedAdmin: { label: 'assigned admin:' },
      assignedOfficeWorker: { label: 'assigned officeworker:' },
    },
    header: {
      taskName: 'Name',
      taskState: 'State',
      taskDescription: 'Description',
      taskDate: 'Due date',
      createdBy: 'Creator',
      assignedAdmins: 'assigned Admins',
      assignedOfficeWorkers: 'assigned Officeworkers',
    },
    edit: {
      fulfill: 'Set to: Done',
      cancel: 'Set to: Created',
    },
  },
  form: {
    taskName: { label: 'Name' },
    taskDescription: { label: 'Description' },
    taskDate: { label: 'Due date:' },
    titleNew: 'New Task',
    createdBy: { label: 'Creator: {0} ' },
    assignedAdmins: { label: 'assigned Admins:' },
    assignedOfficeWorkers: { label: 'assigned Officeworkers:' },
  },
  usageDialog: {
    usage: 'Description',
    close: 'close',
  },
  event: {
    details: {
      taskState: 'Task state',
      taskDate: 'Task date',
      taskName: 'Name',
    },
  },

};
