import en from 'vuetify/src/locale/en';

import calendar from '../modules/calendar/locale/en';
import documenttranslation from '../modules/contract/documenttranslation/locale/en';
import onsitetranslation from '../modules/contract/onsitetranslation/locale/en';
import videotranslation from '../modules/contract/videotranslation/locale/en';
import customer from '../modules/customer/locale/en';
import customergroup from '../modules/customer/customergroup/locale/en';
import language from '../modules/language/locale/en';
import businessunit from '../modules/businessunit/locale/en';
import settings from '../modules/settings/locale/en';
import translator from '../modules/translator/locale/en';
import user from '../modules/user/locale/en';
import officeworker from '../modules/officeworker/locale/en';
import admin from '../modules/admin/locale/en';
import systemconfiguration from '../modules/systemconfiguration/locale/en';
import report from '../modules/report/locale/en';
import bankaccounttransaction from '../modules/bankaccounttransaction/locale/en';
import cashaccounttransaction from '../modules/cashaccounttransaction/locale/en';
import invoice from '../modules/financialdocument/invoice/locale/en';
import credit from '../modules/financialdocument/credit/locale/en';
import contact from '../modules/contact/locale/en';
import costcenter from '../modules/costcenter/locale/en';
import emailtemplate from '../modules/emailtemplate/locale/en';
import email from '../modules/email/locale/en';
import externaldocument from '../modules/financialdocument/externaldocument/locale/en';
import task from '../modules/task/locale/en';
import starmoneyimport from '../modules/starmoneyimport/locale/en';
import cashbook from '../modules/cashbook/locale/en';
import vatconfiguration from '../modules/vatconfiguration/locale/en';


export default {
  $vuetify: {
    close: 'Close',
    dataIterator: {
      pageText: '{0}-{1} of {2}',
      noResultsText: 'No matching records found',
      loadingText: 'Loading items...',
    },
    dataTable: {
      itemsPerPageText: 'Rows per page:',
      ariaLabel: {
        sortDescending: ': Sorted descending. Activate to remove sorting.',
        sortAscending: ': Sorted ascending. Activate to sort descending.',
        sortNone: ': Not sorted. Activate to sort ascending.',
      },
      sortBy: 'Sort by',
    },
    dataFooter: {
      itemsPerPageText: 'Items per page:',
      itemsPerPageAll: 'All',
      nextPage: 'Next page',
      prevPage: 'Previous page',
      firstPage: 'First page',
      lastPage: 'Last page',
    },
    datePicker: {
      itemsSelected: '{0} selected',
    },
    noDataText: 'Type to search',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
    },
    calendar: {
      moreEvents: '{0} Entries',
    },
    fileInput: {
      counter: '{0} files',
      counterSize: '{0} files ({1} in total)',
    },
  },
  title: '{company} - Office Software',
  module: {
    calendar,
    documenttranslation,
    onsitetranslation,
    videotranslation,
    customer,
    customergroup,
    language,
    businessunit,
    settings,
    translator,
    user,
    officeworker,
    admin,
    systemconfiguration,
    report,
    bankaccounttransaction,
    cashaccounttransaction,
    invoice,
    contact,
    costcenter,
    emailtemplate,
    email,
    credit,
    externaldocument,
    task,
    starmoneyimport,
    cashbook,
    vatconfiguration,
  },
  menu: {
    users: 'Users',
    masterData: 'Master Data',
    contracts: 'Contracts',
    accounting: 'Accounting',
    dashboard: 'Dashboard',
    appointment: 'Appointment',
    settings: 'Settings',
  },
  login: {
    title: 'Login',
    error: 'Unknown e-mail address or password.',
    ref: {
      expired: 'Your session expired. Please log in again.',
      reset: 'Your password is reset. Please log in as usual.',
    },
    email: {
      label: 'E-mail address',
    },
    password: {
      label: 'Password',
      hintCapslock: 'Caps lock active?',
    },
    submit: {
      label: 'Login',
    },
    requestPassword: {
      label: 'Forgot password',
      success: 'An e-mail was sent to {email} if email is known. Please follow the link in this email to reset your password. The link is valid until {expires}.',
    },
  },
  resetPassword: {
    title: 'Reset Password',
    titleActivation: 'Activate User - Set Passwort',
    password: {
      label: 'New Password',
    },
    passwordConfirm: {
      label: 'Repeat New Password',
    },
    submit: {
      label: 'Reset Password',
    },
    toLogin: {
      label: 'Login',
    },
  },
  logout: {
    tooltip: 'Logout',
  },

  hintOnlyReadOnly: 'Hint: You have only perssion to read.',

  // buttons
  submit: {
    label: 'Save',
  },
  reset: {
    label: 'Reset',
  },
  refresh: {
    label: 'Refresh',
  },
  add: {
    label: 'Add',
  },
  edit: {
    label: 'Edit',
  },
  delete: {
    label: 'Delete',
  },
  cancel: {
    label: 'Cancel',
  },
  close: {
    label: 'Close',
  },
  no: {
    label: 'No',
  },
  yes: {
    label: 'Yes',
  },
  ok: {
    label: 'OK',
  },
  filter: {
    label: 'Filter',
  },

  // messages
  unsavedChanges: {
    title: 'Unsaved Changes',
    msg: 'There are unsaved changes. Would you like to save them now?',
  },

  // shared
  list: {
    loading: 'Loading...',
    header: {
      id: 'ID',
    },
    filter: 'Filter',
    delete: {
      title: 'Delete', // of the confirmation dialog
      success: 'Item deleted',
    },
  },
  form: {
    save: {
      success: 'Changes saved',
    },
  },

  // quill editor
  quill: {
    font: {
      tooltip: 'Fontstyle',
      default: 'Normal',
      serif: 'Serif',
      monospace: 'Monospace',
    },
    header: {
      tooltip: 'Headline',
      default: 'Normal',
      1: 'Header 1',
      2: 'Header 2',
      3: 'Header 3',
      4: 'Header 4',
      5: 'Header 5',
      6: 'Header 6',
    },
    size: {
      tooltip: 'Fontsize',
      default: 'Normal',
      small: 'Small',
      large: 'Large',
      huge: 'Huge',
    },
    bold: {
      tooltip: 'Bold',
    },
    italic: {
      tooltip: 'Italic',
    },
    underline: {
      tooltip: 'Underlined',
    },
    strike: {
      tooltip: 'crossed out',
    },
    align: {
      tooltip: {
        default: 'Left-aligned',
        center: 'Centred',
        right: 'Right-aligned',
        justify: 'Justified',
      },
    },
    script: {
      tooltip: {
        sub: 'Subscript',
        super: 'Superscript',
      },
    },
    color: {
      tooltip: 'Textcolor',
    },
    background: {
      tooltip: 'Backgroundcolor',
    },
    list: {
      tooltip: {
        ordered: 'Order',
        bullet: 'Listing',
      },
    },
    indent: {
      tooltip: {
        indent: 'Intend',
        outdent: 'Outdent',
      },
    },
    link: {
      tooltip: 'Hyperlink',
      title: 'Insert Link',
      label: 'URL (incl. http://)',
      delete: {
        label: 'Remove Link',
      },
    },
    image: {
      tooltip: 'Add Graphic',
      errorSize: 'Filesize may not exceed {maxFileSize}.',
    },
    clean: {
      tooltip: 'Remove formatting',
    },
  },

  error: {
    // server exceptions
    server: {
      NOT_FOUND: {
        ADMIN: 'Administrator with ID {0} not found.',
        BUSINESS_UNIT: 'Business unit with ID {0} not found.',
        BANK_ACCOUNT: 'Bank account with ID {0} not found.',
        DOCUMENT_TRANSLATION_CONTRACT: 'Contract with ID {0} not found.',
        ON_SITE_TRANSLATION_CONTRACT: 'Contract with ID {0} not found.',
        VIDEO_TRANSLATION_CONTRACT: 'Contract with ID {0} not found.',
        CUSTOMER: 'Customer with ID {0} not found.',
        LANGUAGE: 'Language with ID {0} not found.',
        TRANSLATOR: 'Translator with ID {0} not found.',
        COST_CENTER: 'Cost center with ID {0} not found.',
        COST_CONFIGURATION_CYCLE_TYPE: 'Cost cycle type with ID {0} not found.',
        CUSTOMER_GROUP: 'Customer group with ID {0} not found.',
        EMAIL_TEMPLATE: 'E-mail template with ID {0} not found.',
        INVOICE: 'Invoice with ID {0} not found.',
        CREDIT: 'Credit with ID {0} not found.',
        OFFICE_WORKER: 'Office worker with ID {0} not found.',
        SYSTEM_CONFIGURATION: 'System configuration "{0}" not found..',
        REPORT: 'Report "{0}" not found.',
      },
      BUSINESS_UNIT: {
        DELETE_NOT_POSSIBLE_BECAUSE_CONTRACTS_EXISTS: 'It is not allowed to delete this business unit, because it is assigned in some contracts.',
        DELETE_NOT_POSSIBLE_BECAUSE_OFFICE_WORKERS_EXISTS: 'It is not allowed to delete this business unit, because it is assigned to some some office workers.',
      },
      COST_CENTER: {
        DELETE_NOT_POSSIBLE_BECAUSE_CUSTOMERS_EXISTS: 'It is not allowed to delete this cost center, because it is assigned to some customers.',
        DELETE_NOT_POSSIBLE_BECAUSE_TRANSLATORS_EXISTS: 'It is not allowed to delete this cost center, because it is assigned to some translators.',
      },
      CUSTOMER_GROUP: {
        DELETE_NOT_POSSIBLE_BECAUSE_TRANSLATOR_COST_CONFIGURATION_EXISTS: 'It is not allowed to delete this customer groups, because it is assigned to some cost configurations of translator.',
      },
      CUSTOMER_LANGUAGE_GROUP: {
        DELETE_NOT_POSSIBLE_BECAUSE_CUSTOMER_COST_CONFIGURATION_EXISTS: 'It is not allowed to delete this language groups, because it is assigned to some cost configurations of customer.',
        DUPLICATE_LANGUAGE_CHECK: 'Language {1} multiple assigned to different language groups. Saving canceled.',
      },
      LANGUAGE: {
        DELETE_NOT_POSSIBLE_BECAUSE_CONTRACTS_EXISTS: 'It is not allowed to delete this language, because it is assigned in some contracts.',
        DELETE_NOT_POSSIBLE_BECAUSE_CUSTOMER_LANGUAGE_GROUPS_EXISTS: 'It is not allowed to delete this language, because it is assigned in some customer language groups.',
        DELETE_NOT_POSSIBLE_BECAUSE_TRANSLATOR_EXISTS: 'It is not allowed to delete this language, because it is assigned to some translators.',
      },
      USER: {
        INVALID_USER_TYPE: 'The user type has to be "{0}".',
        INVALID_USER_TYPE_CONTACT: 'Contact information for user type "{0}" not available.',
        DELETE_NOT_POSSIBLE_BECAUSE_CONTRACTS_EXISTS: 'It is not allowed to delete this user, because he is assigned in some contracts.',
        DELETE_NOT_POSSIBLE_BECAUSE_CREDITS_EXISTS: 'It is not allowed to delete this user, because he is assigned in some credits.',
        DELETE_NOT_POSSIBLE_BECAUSE_INVOICES_EXISTS: 'It is not allowed to delete this user, because he is assigned in some invoices.',
        NO_PERMISSION: 'No Permission ({0})!',
        DUPLICATE_USER_ADMIN: 'Email address {0} exists already for another user(administrator). Please chooses another email address.',
        DUPLICATE_USER_OFFICE_WORKER: 'Email address {0} exists already for another user(office worker). Please chooses another email address.',
        DUPLICATE_USER_CUSTOMER: 'Email address {0} exists already for another user(customer). Please chooses another email address.',
        DUPLICATE_USER_TRANSLATOR: 'Email address {0} exists already for another user(translator). Please chooses another email address.',
      },
      PASSWORD_CHANGE: {
        USER_WITH_EMAIL_NOT_FOUND: 'The e-mail address {0} is not available.',
        ACTION_NOT_ALLOWED_FOR_LOCKED_USER: 'The desired step is forbidden.',
        OLD_PASSWORD_WRONG: 'The current password is not correct.',
        PASSWORD_LINK_NOT_VALID: 'Link not available.',
        PASSWORD_LINK_EXPIRED: 'Link expired.',
      },
      FINANCIAL_DOCUMENT: {
        DELETE_NO_POSSIBLE_BECAUSE_ASSIGNMENT_TO_BANK_ACCOUNT_TRANSACTION_EXISTS:
          'Delete of financial document is not possible, because a corresponding bank account transaction exists. Please delete it first.',
        DELETE_NO_POSSIBLE_BECAUSE_ASSIGNMENT_TO_CASH_ACCOUNT_TRANSACTION_EXISTS:
          'Delete of financial document is not possible, because a corresponding cash account transaction exists. Please delete it first.',
        },
      INVOICE: {
        APPROVEMENT_WITHOUT_PDF_CREATION_NOT_ALLOWED: 'The selected invoices can\'t be approved until the PDF for the invoice is created',
        NO_CONTRACTS_DEFINED: 'No contracts selected.',
        NO_TRANSLATOR_DEFINED: 'No translator at contract',
        COST_CENTER_UNDEFINED: 'The cost center of the customer is not defined.',
        RECEIVER_OPTION_UNDEFINED: 'There isn\'t any invoice address option defined.',
        INVOICE_CREATION_NOT_ALLOWED_FOR_CURRENT_CONTRACT_STATE: 'Invoice creation is only allowed for fulfilled or cancelled contracts',
        CUSTOMER_LANGUAGE_GROUP_AMBIGUOUS: 'The language {1} is assigned to multiple language groups for customer {2}.',
        CUSTOMER_LANGUAGE_GROUP_NOT_FOUND: 'The language {1} isn\'t assigned to a language group for customer {2}.',
        COST_CONFIG_CYCLE_NOT_FOUND: 'There isn\'t any billing cycle {2} defined for the language group {1} for customer {0}.',
        COST_CONFIG_CYCLE_AMBIGUOUS: 'There are multiple billing cycles {2} defined for the language group {1} for customer {0}.',
        INVALID_INVOICE_REFERENCE: 'Invoice reference must contain unique Number {0}.',
        REVERSAL_INVOICE_REFERENCE_ALREADY_EXISTS: 'Creation of reversal invoice is not possible, because an invoice with invoice no {0} already exists.',
        REVERSAL_INVOICE_ALREADY_EXISTS: 'Creation of reversal invoice is not possible, because a reversal invoice with invoice no {1} already exists for invoice with invoice no {0}.',
        ONLY_APPROVED_INVOICES_CAN_BE_CANCELED: 'Creation of reversal invoice is not possible, because invoice {0} is not in state {0} confirmed.',
        DELETE_NOT_POSSIBLE_BECAUSE_REVERSAL_INVOICE_EXISTS: 'Delete of invoice is not possible, because a corresponding reversal invoice exists. Please delete it first.',
        WAIT_TIME_COULD_NOT_BE_GREATER_THAN_TRANSLATION_TIME: 'Wait time should not be greater than operartion time.',
        COST_PER_KM_NOT_SET: 'Creation of invoice for contract {0} is not possible - input missing: travel distance in km',
        FIX_RATE_NOT_SET: 'Creation of invoice for contract {0} is not possible - input missing: Fix Cost in €',
        PUBLIC_TRANSIT_TICKET_COST_NOT_SET: 'Creation of invoice for contract {0} is not possible - input missing: ticket costs',

      },
      CREDIT: {
        APPROVEMENT_WITHOUT_PDF_CREATION_NOT_ALLOWED: 'The selected credits can\'t be approved until the PDF for the invoice is created',
        COST_CENTER_UNDEFINED: 'The cost center of the customer is not defined.',
        COST_CONFIG_CYCLE_AMBIGUOUS: 'There isn\'t any billing cycle {2} defined for the language group {1} for translator {0}.',
        COST_CONFIG_CYCLE_NOT_FOUND: 'There isn\'t any billing cycle {2} defined for the language group {1} for translator {0}.',
        CREDIT_CREATION_NOT_ALLOWED_FOR_CURRENT_CONTRACT_STATE: 'Credit creation is only allowed for fulfilled or cancelled contracts',
        NO_CONTRACTS_DEFINED: 'No contracts selected.',
        NO_TRANSLATOR_DEFINED: 'No translator at contract',
        RECEIVER_OPTION_UNDEFINED: 'There isn\'t any credit address option defined.',
        WAIT_TIME_COULD_NOT_BE_GREATER_THAN_TRANSLATION_TIME: 'Wait time should not be greater than operartion time.',
        COST_PER_KM_NOT_SET: 'Creation of credit for contract {0} is not possible - input missing: travel distance in km',
        FIX_RATE_KM_NOT_SET: 'Creation of credit for contract {0} is not possible - input missing: Fix Cost in €',
        PUBLIC_TRANSIT_TICKET_COST_NOT_SET: 'Creation of credit for contract {0} is not possible - input missing: ticket costs',
        REVERSAL_CREDIT_REFERENCE_ALREADY_EXISTS: 'Creation of reversal credit is not possible, because an credit with credit no {0} already exists.',
        REVERSAL_CREDIT_ALREADY_EXISTS: 'Creation of reversal credit is not possible, because a reversal credit with credit no {1} already exists for credit with credit no {0}.',
        ONLY_APPROVED_CREDIT_CAN_BE_CANCELED: 'Creation of reversal credit is not possible, because credit {0} is not in state {0} confirmed.',
        DELETE_NOT_POSSIBLE_BECAUSE_REVERSAL_CREDIT_EXISTS: 'Delete of credit is not possible, because a corresponding reversal credit exists. Please delete it first.',

      },
      CONTACT: {
        MISSING_CITY: 'Input City missing.',
        MISSING_ZIP_CODE: 'Input ZIP Code missing..',
        MISSING_STREET: 'Input Street missing.',
        MISSING_COMPANY_OR_INCOMPLETE_NAME_OF_PERSON: 'Input to company or name incomplete.',
        INCONSISTENT_CITY: 'Contact informations to cities are not identical.',
        INCONSISTENT_ZIP_CODE: 'Contact informations to zipcodes are not identical.',
        INCONSISTENT_STREET: 'Contact informations to streets are not identical.',
        INCONSISTENT_COUNTRY: 'Contact informations to countries are not identical.',
        INCONSISTENT_LAST_NAME: 'Contact informations to lastname are not identical.',
        INCONSISTENT_FIRST_NAME: 'Contact informations to firstname are not identical',
        INCONSISTENT_COMPANY: 'Contact informations to companies are not identical.',
      },
      CONTRACT: {
        FULFILLMENT_CHECK_MISSING_LINES_OF_TRANSLATED_FILE: 'You have to input number of lines of translation to fulfill contract {0}.',
        FULFILLMENT_CHECK_MISSING_REAL_TRANSLATION_DURATION_ON_SITE: 'You have to input real duration in minutes to fulfill contract {0}.',
      },
      FILE: {
        NOT_FOUND: 'File not found',
        FILE_DELETE_FAILED: 'File deletion failed.',
        FILE_SAVE_FAILED: 'Saving the file failed.',
        BASE_DIR_CREATE_FAILED: 'Directory attachment on server failed.',
        DUPLICATE_ATTACHMENT: 'Attachments with identical name is not allowed.',
      },
      REPORT: {
        NOT_FOUND: 'Report not found.',
        CREATE_FAILED: 'Report could not be created.',
        INVALID_REPORT_DATES: 'invalid dates for creation of report',
      },
      EMAIL: {
        SEND_FAILED: 'Send of email failed.',
        SEND_DISABLED: 'Send of email is deactivated. Please activate in system settings if needed.',
      },
      STAR_MONEY_IMPORT: {
        COMPLETED_IMPORT_NOT_ALLOWED_TO_DELETE: 'For reasons of traceability, successfully imported records must not be deleted.',
      },
      VAT: {
        NO_VAT_RATE_FOUND: 'No valid VAT rate fpo date {0}.',
        DUPLICATE_VAT_CONFIG_ENTRY: 'Duplicate entry for same combination of VAT and valid-from-Date is not permitted.',
      },
    },
  },

  // special keys

  $nth: (n: number) => `${n}${['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][n > 3 && n < 21 ? 0 : n % 10]}`,

  $dateTimeFormats: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
    dateTime: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    },
    eventTime: {
      hour: 'numeric',
      minute: '2-digit',
    },
  },

  $numberFormats: {
    decimal: {
      style: 'decimal',
    },
    integer: {
      style: 'decimal',
      maximumFractionDigits: 0,
    },
    fileSize: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    currency: {
      style: 'currency',
      currency: process.env.VUE_APP_CURRENCY || 'EUR',
      currencyDisplay: 'symbol',
    },
  },
};
