export default {
  title: 'Business Units',
  list: {
    header: {
      name: 'Business Unit',
    },
    delete: {
      title: 'Delete Business Unit',
      message: 'Do you really want to delete the business unit {id}?',
    },
  },
  form: {
    titleNew: 'New Business Unit',
    name: {
      label: 'Name',
    },
    invoiceNoPrefixOnSiteContract: {
      label: 'Invoice Number Prefix: On-site contract',
    },
    invoiceNoPrefixDocumentContract: {
      label: 'Invoice Number Prefix: Document contract',
    },
    invoiceNoPrefixVideoTranslationContract: {
      label: 'Invoice Number Prefix: Video translation contract',
    },
    creditNoPrefixOnSiteContract: {
      label: 'Credit Number Prefix: On-site contract',
    },
    creditNoPrefixDocumentContract: {
      label: 'Credit Number Prefix: Document contract',
    },
    creditNoPrefixVideoTranslationContract: {
      label: 'Credit Number Prefix: Video translation contract',
    },
  },
  invoiceContact: {
    title: 'Invoice Contact',
  },
  bankAccountCollection: {
    title: 'Bank details',
  },
  bankaccountcollectionform: {
    title: 'Bank details',
    noData: 'No Bank details ',
    bankName: {
      label: 'Name of the bank',
    },
    IBAN: {
      label: 'IBAN',
    },
    BIC: {
      label: 'BIC',
    },
    isActive: {
      label: 'Is the bank account active?',
    },
  },
};
