export default {
  title: 'Customer Groups',
  list: {
    header: {
      name: 'Name',
    },
    delete: {
      title: 'Delete Customer Group',
      message: 'Do you really want to delete the customer group {name}?',
    },
  },
  form: {
    titleNew: 'New Customer Group',
    name: {
      label: 'Name',
    },
  },
};
