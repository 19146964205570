import base from '../../locale/en';
import _ from 'lodash';

export default _.merge(_.cloneDeep(base), {
  title: 'Document Translations',
  usageDialog: {
    usage: 'Comment',
    close: 'close',
  },
  warningDialog: {
    usage: 'Hint',
    close: 'close',
    creditAlreadyExists: 'For selected contracts exists at least one credit.',
    createCreditForced: 'create additional credit',
    invoiceAlreadyExists: 'For selected contracts exists at least one invoice.',
    createInvoiceForced: 'create additional invoice',
    hasMoreThanOneTranslator: 'For creation of credits are selection with only translator permitted. Please change your selection of contracts.',
    hasMoreThanOneCustomer: 'For creation of invoices are selection with only customer permitted. Please change your selection of contracts.',
    isCreditAlreadyCreated: 'For at least one contract of your selected contracts exists a already a credit.',
    isInvoiceAlreadyCreated: 'For at least one contract of your selected contracts exists a already a invoice.',
  },
  list: {
    header: {
      contractNo: 'Contract Number',
      contractState: 'State',
      businessUnit: 'Business Unit',
      customer: 'Customer',
      translator: 'Translator',
      translatorLanguage: 'Language',
      contractDate: 'Contract Date',
      contractComment: 'Comment',
    },
    filter: {
      contractNo: {
        label: 'Contract Number',
      },
      contractState: {
        label: 'State',
      },
      contractComment: {
        label: 'Comment contains',
      },
      businessUnit: {
        label: 'Business Unit',
      },
      customer: {
        label: 'Customer',
      },
      translator: {
        label: 'Translator',
      },
      contractDateFrom: {
        label: 'Contract Date from',
      },
      contractDateUntil: {
        label: 'Contract Date until',
      },
      invoiceFilter: {
        label: 'Invoice',
      },
      creditFilter: {
        label: 'Credit',
    },
    language: {
      label: 'Language',
    },
    invoiceId: {
      label: 'Invoice',
    },
    creditId: {
      label: 'Credit',
    },
  },
  invoiceCreated: 'Invoice created',
  creditCreated: 'Credit created',
  invoiceAndCreditCreated: 'Invoice & Credit created',
    edit: {
      contractState: {
        fulfill: 'Contract fulfilled',
        cancel: 'Cancel Contract',
        confirmCancel: {
          title: 'Cancel Contract',
          message: 'Do you really want to cancel the contract {contractNo} now?',
          yes: {
            label: 'Cancel Contract',
          },
        },
      },
      translator: {
        assign: 'assign',
        confirm: 'Confirm Translator',
        label: 'Select Translator',
      },
      sendEmail: {
        title: 'Send E-mail',
      },
    },
    actions: {
      showInvoice: 'Show Invoice',
      showCredit: 'Show Credit',
      sendEmailToTranslator: 'Send E-mail to Translator',
      sendEmailToCustomer: 'Send E-mail to Customer',
    },
    createInvoice: {
      label: 'Create Invoice',
    },
    createCredit: {
      label: 'Create Credit',
    },
  },
  form: {
    titleNew: 'New Contract',
    completeUntil: {
      label: 'Translation due',
    },
    linesOfOriginalFile: {
      label: 'Number of Lines of Original Document',
    },
    linesOfTranslatedFile: {
      label: 'Number of Lines of Translation',
    },
    save: {
      success: 'Changes saved for contract with the contract number {contractNo}.',
    },
    contractComment: {
      label: 'Comment',
    },
    createCustomer: {
      label: 'Create Customer',
    },
  },
  customerCommentDialog: {
    content: 'Comment - Customer',
    close: 'Close',
  },
  translatorCommentDialog: {
    content: 'Comment - Translator',
    close: 'Close',
  },
  fileform: {
    title: 'File attachments',
    noData: 'No File attachments',
    filename: 'filename',
    uploadRequest: 'Please upload your attachment.',
    file: 'File',
  },
  fileAttachmentCollectionOriginal: {
    title: 'Original documents',
  },
  fileAttachmentCollectionTranslated: {
    title: 'translated documents',
  },
  invoiceContact: {
    title: 'Invoice contact',
  },
  creditContact: {
    title: 'Credit contact',
  },
  email: {
    title: 'send e-mail',
    to: {
      label: 'to',
    },
    cc: {
      label: 'Copy to',
    },
    subject: {
      label: 'Subject',
    },
    body: {
      label: 'Content',
    },
    send: {
      label: 'Send',
      },
  },
  event: {
    details: {
      contractState: 'State',
      businessUnit: 'Business Unit',
      customer: 'Customer',
      translator: 'Translator',
      contractDate: 'Contract Date',
      completeUntil: 'To translate until',
    },
  },
});
