export default {
  title: 'Invoices',
  download: 'Download - {0}',
  dialog: {
    cancelInvoice: {
      title: 'Cancel Invoice',
      text: 'Do you really want to cancel the invoices??',
      cancel: 'Cancel',
      close: 'Close',
    },
    setStateAdmin: {
      title: 'Set State - ADMIN-ACTION!',
      text: 'Please choose your target state.',
      close: 'Close',
    },
    showDownloadPdfZipCheck: {
      title: 'Hint for Accumulative Download',
      text: 'For following invoices are not PDF files found:',
      downloadForced: 'download other files',
      close: 'Close',
    },
    warning: {
      title: 'Warning!',
      invoiceNotApproved: 'Only confirmed invoices can be canceled.',
      close: 'Close',
    },
  },
  usageDialog: {
    usage: 'Comment',
    close: 'Close',
  },
  message: {
    reversalInvoiceCreated: 'Reversal invoice created.',
  },
  enums: {
    InvoiceState: {
      CREATED: 'created',
      APPROVED: 'approved',
      CANCELED: 'canceled',
    },
    Vat: {
      ZERO: 'zero',
      REDUCED: 'reduced',
      FULL: 'full',
    },
    InvoiceType: {
      DOCUMENT_TRANSLATION: 'Document Translation',
      ON_SITE_TRANSLATION: 'On Site Translation',
      VIDEO_TRANSLATION: 'Video Translation',
    },
  },
  list: {
    reversalTo: 'reversal to',
    cancelInvoice: {
      label: 'cancel invoice',
    },
    downloadAccumulativePDF: {
      label: 'Download-PDF (ZIP)',
    },
    setStateAdmin: {
      label: 'set state (ADMIN)',
    },
    actions: {
      sendEmailToCustomer: 'Send e-mail to customer',
    },
    header: {
      invoiceReference: 'Invoice number',
      invoiceState: 'State',
      invoiceDate: 'Invoice date',
      customer: 'Customer',
      businessUnit: 'Businessunit',
      invoiceComment: 'Comment',
      isPaymentCompleted: 'Payment completed',
      downloadPDF: 'PDF',
    },
    delete: {
      title: 'Delete invoice',
      message: 'Should the invoice really be deleted?',
    },
    filter: {
      invoiceReference: {
        label: 'Invoice number',
      },
      invoiceState: {
        label: 'State',
      },
      invoiceComment: {
        label: 'Comment',
      },
      contractNo: {
        label: 'Contract number',
      },
      customer: {
        label: 'Customer',
      },
      translator: {
        label: 'Translator',
      },
      businessUnit: {
        label: 'Businessunit',
      },
      invoiceDateFrom: {
        label: 'Invoice date from',
      },
      invoiceDateUntil: {
        label: 'Invoice date until',
      },
      isPaymentCompleted: {
        label: 'Payment completed',
        true: 'Yes',
        false: 'No',
      },
      invoiceType: {
        label: 'Invoice type',
      },
      pdfCreatorAdmin: {
        label: 'Invoice-PDF-Creator (Admin)',
      },
      pdfCreatorOfficeWorker: {
        label: 'Invoice-PDF-Creator (Office Worker)',
      },
      isCreatedPDF: {
        label: 'Invoice-PDF created',
        true: 'Yes',
        false: 'No',
      },
    },
    edit: {
      fulfill: 'Fulfill invoice ',
      sendEmail: {
        title: 'Send e-mail',
      },
    },
  },
  form: {
    headerInfo: {
      label: 'Invoice informations',
    },
    senderContact: {
      label: 'Sender',
    },
    receiverContact: {
      label: 'Receiver',
    },
    invoicePositions: {
      label: 'Positions',
    },
    createPDF: {
      label: 'create PDF',
    },
    invoiceFiles: {
      label: 'Download invoice',
    },
    invoiceDate: {
      label: 'Invoice date',
    },
    invoiceReference: {
      label: 'Invoice number',
    },
    operationDate: {
      label: 'Operation date',
    },
    operationTime: {
      label: 'Operation time',
    },
    operationLocation: {
      label: 'Operation location',
    },
    language: {
      label: 'Language',
    },
    customerAsText: {
      label: 'Customer',
    },
    translatorAsText: {
      label: 'Translator',
    },
    reference: {
      label: 'Reference',
    },
    notice: {
      label: 'Notice',
    },
    invoiceComment: {
      label: 'Comment',
    },
    explanation: {
      label: 'Explanation',
    },
    isPaymentCompleted: {
      label: 'Payment completed',
    },
    fileCreatedBy: {
      label: 'Creator: {0}',
    },
  },
  invoiceSummary: {
    header: 'Summary',
    subtotal: 'Subtotal',
    vat: 'Additionally 19% VAT',
    total: 'Total incl. VAT',
  },
  positionList: {
    header: {
      description: 'Position',
      count: 'Amount',
      countUnit: 'Unit (Amount)',
      unitPriceNet: 'Price per unit (net)',
      unitPriceNetUnit: 'Unit (Price per unit)',
      totalPriceNet: 'Gesamtsumme (net)',
      vat: 'Vat',
      vatRate: 'Vat Rate',
    },
    edit: {
      addInvoicePosition: {
        label: 'New position',
      },
      description: {
        label: 'Position',
      },
      count: {
        label: 'Amount',
      },
      countUnit: {
        label: 'Unit (Amount)',
      },
      unitPriceNet: {
        label: 'Price per unit (net)',
      },
      unitPriceNetUnit: {
        label: 'Unit (Price per unit)',
      },
      totalPriceNet: {
        label: 'Gesamtsumme (net)',
      },
      vat: {
        label: 'Vat',
      },
    },
  },
  email: {
    title: 'Send e-mail',
    to: {
      label: 'to',
    },
    cc: {
      label: 'Copy to',
    },
    subject: {
      label: 'Subject',
    },
    body: {
      label: 'Content',
    },
    send: {
      label: 'Send',
    },
  },
  fileform: {
    title: 'File attachments',
    noData: 'No File attachments',
    filename: 'filename',
    uploadRequest: 'Please upload your attachment.',
    file: 'File',
  },
  event: {
    title: 'Invoice - {invoiceReference}',
    details: {
      invoiceReference: 'Invoice number',
      invoiceState: 'State',
      businessUnit: 'Businessunit',
      customer: 'Customer',
      invoiceDate: 'Invoice date',
      timeForPayment: 'Due date',
    },
  },
};
