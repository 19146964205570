export default {
  title: 'Cashbook',
  form: {
    businessUnit: 'Businessunit',
    fromDate: 'from',
    toDate: 'to',
    button: {
      submit: 'Create cashbook',
    },
  },
};
