export default {
  title: 'Reports',
  form: {
    titleNew: 'New report',
    hints: {
      moneyReport: 'GT - relevant for Excel-Sheet - Money transactions',
      contractReport: 'A - relevant for Excel-Sheet - Contracts',
    },
    header: {
      Groupings: 'grouping options',
      Filter: 'filter options',
    },
    fromDate: {
      label: 'Report start',
    },
    toDate: {
      label: 'Report end',
    },
    isGroupedByLanguage: {
      label: 'Grouped by language (A)',
    },
    language: {
      label: 'language (A)',
    },
    isGroupedByBusinessUnit: {
      label: 'Grouped by businessunit (GT/A)',
    },
    businessUnit: {
      label: 'businessunit (GT/A)',
    },
    isGroupedByContractType: {
      label: 'Grouped by type of contract (A)',
    },
    contractType: {
      label: 'type of contract (A)',
    },
    isGroupedByContractNo: {
      label: 'Grouped by contract number (A)',
    },
    contractNo: {
      label: 'contract number (A)',
    },
    isGroupedByContractState: {
      label: 'Grouped by contract state (A)',
    },
    contractState: {
      label: 'contract state (A)',
    },
    isGroupedByTranslator: {
      label: 'Grouped by translator (A)',
    },
    translator: {
      label: 'translator (A)',
    },
    isGroupedByCustomer: {
      label: 'Grouped by customer (A)',
    },
    customer: {
      label: 'customer (A)',
    },
    isGroupedByMoneyOrigin: {
      label: 'Grouped by origin (GT)',
    },
    reportMoneyOrigin: {
      label: 'origin (GT)',
    },
    isGroupedByAssignmentState: {
      label: 'Grouped by assignment state (GT)',
    },
    bankAccountTransactionAssignmentState: {
      label: 'assignment state (GT)',
    },
    isGroupedByFinancialDocumentType: {
      label: 'Grouped by type of document (GT)',
    },
    financialDocumentType: {
      label: 'type of document (GT)',
    },
    isGroupedByInvoiceState: {
      label: 'Grouped by invoice state (A)',
    },
    invoiceState: {
      label: 'invoice state (A)',
    },
    isGroupedByInvoicePaymentState: {
      label: 'Grouped by invoice payment state (A)',
    },
    invoicePaymentState: {
      label: 'invoice payment state (A)',
    },
    isGroupedByOperationLocation: {
      label: 'Grouped by operation location (A)',
    },
    operationLocation: {
      label: 'operation location contains (A)',
    },
    reportTimeGroup: {
      label: 'Temporal grouping (GT/A)',
    },
  },
  list: {
    delete: {
      title: 'Delete report',
      message: 'Should the report {id} really be deleted?',
    },
    header: {
      reportState: 'Report state',
      fromDate: 'Report start',
      toDate: 'Report end',
      createDate: 'Created at',
      groupSummary: 'Grouped by',
      filterSummary: 'Filtered by',
    },
    groupSummary: {
      isGroupedByLanguage: 'language',
      isGroupedByBusinessUnit: 'businessunit',
      isGroupedByContractType: 'type of contracts',
      isGroupedByContractNo: 'contract numbers',
      isGroupedByContractState: 'contract state',
      isGroupedByTranslator: 'translator',
      isGroupedByCustomer: 'customers',
      isGroupedByMoneyOrigin: 'origin',
      isGroupedByAssignmentState: 'assignment state',
      isGroupedByFinancialDocumentType: 'type of document',
      isGroupedByInvoiceState: 'invoice state',
      isGroupedByInvoicePaymentState: 'invoice payment state',
      isGroupedByOperationLocation: 'operation location',
      reportTimeGroup: {
        WEEK: 'Weeks',
        MONTH: 'Months',
        YEAR: 'Years',
      },
    },
    filteredBy: {
      language: {
        label: 'language',
      },
      businessUnit: {
        label: 'businessunit',
      },
      contractType: {
        label: 'type of contract',
      },
      contractNo: {
        label: 'contract number',
      },
      contractState: {
        label: 'contract state',
      },
      translator: {
        label: 'translator',
      },
      customer: {
        label: 'customer',
      },
      reportMoneyOrigin: {
        label: 'origin',
      },
      bankAccountTransactionAssignmentState: {
        label: 'assignment state',
      },
      financialDocumentType: {
        label: 'type of document',
      },
      invoiceState: {
        label: 'invoice state',
      },
      invoicePaymentState: {
        label: 'invoice payment state',
      },
      operationLocation: {
        label: 'operation location',
      },
    },
    filter: {
      reportState: {
        label: 'Report-state',
      },
    },
  },
  enums: {
    ReportState: {
      CREATED: 'Report requested',
      CREATION_FAILED_DATA_COLLECT: 'Error while collection data',
      CREATION_FAILED_PDF_CREATION: 'Error while creating Excel-Report-File',
      CREATION_FINISHED: 'Report created',
    },
    ReportTimeGroup: {
      DAY: 'Grouped by Days',
      WEEK: 'Grouped by Weeks',
      MONTH: 'Grouped by Months',
      YEAR: 'Grouped by Years',
    },
    ReportMoneyOrigin: {
      BANK: 'Bank',
      CASH: 'Petty Cash',
    },
    FinancialDocumentType: {
      CREDIT: 'Credit',
      INVOICE: 'Invoice',
      EXTERNAL_DOCUMENT: 'External document',
    },
    ContractType: {
      DOCUMENT_TRANSLATION: 'Document Translation',
      ON_SITE_TRANSLATION: 'On Site Translation',
      VIDEO_TRANSLATION: 'Video Translation',
    },
    ContractState: {
      CREATED: 'Created',
      TRANSLATOR_ASSIGNED: 'Translator assigned',
      TRANSLATOR_CONFIRMED: 'Translator confirmed',
      CONTRACT_FULFILLED: 'Fulfilled',
      CANCELED: 'Canceled',
    },
    InvoicePaymentState: {
      PAID: 'paid',
      NOT_PAID: 'not paid',
    },
  },
};
