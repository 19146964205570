export default {
  title: 'StarMoney Import',
  list: {
    header: {
      fileName: 'Filename',
      importState: 'State',
      uploadedBy: 'Uploaded by',
      uploadedAt: 'Uploaded at',
    },
    delete: {
      title: 'Delete StarMoney Import',
      message: 'Should the import really be deleted?',
    },
    filter: {
      fileName: 'Filename contains',
      importState: 'State',
      uploadedAtFrom: 'Uploaded at - from',
      uploadedAtTo: 'Uploaded at - until',
    },
  },
  fileform: {
    filename: 'Filename',
  },
  enums: {
    StarMoneyImportState: {
      READY_FOR_IMPORT: 'Ready for import',
      ERROR_WRONG_FILE_NAME_FORMAT: 'Error - Formatting of Filename is not correct',
      ERROR_INVALID_BANK_ACCOUNT_NUMBER: 'Error - bank account number invalid',
      ERROR_WHILE_PROCESSING: 'Unexpected error while processing',
      IMPORT_SUCCESSFUL: 'Imported successfully',
    },
  },
};
