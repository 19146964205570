export default {
  title: 'Languages',
  list: {
    header: {
      isoCode: 'ISO-Code',
      name: 'Name',
    },
    delete: {
      title: 'Delete Language',
      message: 'Do you really want to delete the language {name}?',
    },
  },
  form: {
    titleNew: 'New Language',
    isoCode: {
      label: 'ISO Code',
      hint: 'Code of <a href="https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes" target="_blank">ISO-639-2/T</a>',
    },
    name: {
      label: 'Name',
    },
  },
};
