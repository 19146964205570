export default {
  title: 'External financial documents',
  fileAttachmentCollection: {
    title: 'File attachments',
  },
  dialog: {
    showDownloadZipCheck: {
      title: 'Hint for Accumulative Download',
      text: 'For following documents are no files attached:',
      downloadForced: 'download other files',
      close: 'Close',
    },
  },
  list: {
    header: {
      documentReference: 'Document number',
      documentDate: 'Document date',
      isIncome: 'Income Document',
      documentValueNet: 'Document value (Net)',
      documentValueGross: 'Document value (Gross)',
      documentCreator: 'Document creator',
      businessUnit: 'Businessunit',
      documentIsVatFull: 'Vat - full',
      documentIsVatReduced: 'Vat - reduced',
      documentIsVatZero: 'Vat - zero',
      downloadFiles: 'File-Attachments',
    },
    downloadAccumulativeFiles: {
      label: 'Download-Attachments (ZIP)',
    },
    delete: {
      title: 'Delete document',
      message: 'Should the document really be deleted?',
    },
    filter: {
      documentReference: {
        label: 'Document number',
      },
      documentCreator: {
        label: 'Document creator contains',
      },
      businessUnit: {
        label: 'Businessunit is',
      },
      hasFilesAttached: {
        label: 'has file attachments',
        true: 'Yes',
        false: 'No',
      },
      documentDateFrom: {
        label: 'Document date from',
      },
      documentDateUntil: {
        label: 'Document date until',
      },
      documentValueFrom: {
        label: 'Document value from',
      },
      documentValueTo: {
        label: 'Document value until',
      },
    },
  },
  form: {
    titleNew: 'new external document',
    documentDate: {
      label: 'Document date',
    },
    documentReference: {
      label: 'Document reference',
    },
    isIncome: {
      label: 'Income Document',
    },
    documentValueNet: {
      label: 'Document value (net)',
    },
    documentValueGross: {
      label: 'Document value (gross)',
    },
    documentCreator: {
      label: 'Document creator',
    },
    businessUnit: {
      label: 'Businessunit',
    },
    isVatFull: {
      label: 'Vat - full',
    },
    isVatReduced: {
      label: 'Vat - reduced',
    },
    isVatZero: {
      label: 'Vat - Zero',
    },
    noticeTextIncome: 'This document leads to an income of {0}.',
    noticeTextSpending: 'This document leads to an output of {0}.',
  },
  fileform: {
    title: 'File attachments',
    noData: 'No File attachments',
    filename: 'filename',
    uploadRequest: 'Please upload your attachment.',
    file: 'File',
  },
};
