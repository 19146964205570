import base from '../../locale/en';
import _ from 'lodash';

export default _.merge(_.cloneDeep(base), {
  title: 'Video Translations',
  usageDialog: {
    usage: 'Comment',
    close: 'Close',
  },
  warningDialog: {
    usage: 'Hint',
    close: 'close',
    creditAlreadyExists: 'For selected contracts exists at least one credit.',
    createCreditForced: 'create additional credit',
    invoiceAlreadyExists: 'For selected contracts exists at least one invoice.',
    createInvoiceForced: 'create additional invoice',
    hasMoreThanOneTranslator: 'For creation of credits are selection with only translator permitted. Please change your selection of contracts.',
    hasMoreThanOneCustomer: 'For creation of invoices are selection with only customer permitted. Please change your selection of contracts.',
    isCreditAlreadyCreated: 'For at least one contract of your selected contracts exists a already a credit.',
    isInvoiceAlreadyCreated: 'For at least one contract of your selected contracts exists a already a invoice.',
  },
  enums: {
    CommunicationSystem: {
      JITSI: 'Jitsi',
      BRAVIS: 'BRAVIS',
    },
  },
  list: {
    header: {
      contractNo: 'Contract Number',
      contractState: 'State',
      businessUnit: 'Business Unit',
      customer: 'Customer',
      translator: 'Translator',
      translatorLanguage: 'Language',
      contractDate: 'Contract Date',
      contractComment: 'Comment',
    },
    filter: {
      contractNo: {
        label: 'Contract Number',
      },
      contractState: {
        label: 'State',
      },
      businessUnit: {
        label: 'Business Unit',
      },
      customer: {
        label: 'Customer',
      },
      translator: {
        label: 'Translator',
      },
      contractDateFrom: {
        label: 'Contract Date from',
      },
      contractDateUntil: {
        label: 'Contract Date until',
      },
      contractComment: {
        label: 'Comment contains',
      },
      invoiceFilter: {
        label: 'Invoice',
      },
      creditFilter: {
        label: 'Credit',
      },
      language: {
        label: 'Language',
      },
    },
    edit: {
      contractState: {
        fulfill: 'Contract fulfilled',
        cancel: 'Cancel Contract',
        confirmCancel: {
          title: 'Cancel Contract',
          message: 'Do you really want to cancel the contract {contractNo} now?',
          yes: {
            label: 'Cancel Contract',
          },
        },
      },
      translator: {
        assign: 'assign',
        confirm: 'Confirm Translator',
        label: 'Select Translator',
      },
    },
    actions: {
      showInvoice: 'Show Invoice',
    },
    createInvoice: {
      label: 'Create Invoice',
    },
  },
  form: {
    titleNew: 'New Contract',
    beginDateTime: {
      label: 'Planned Start',
    },
    plannedTranslationDuration: {
      label: 'Planned Duration in minutes',
    },
    communicationSystem: {
      label: 'Communication System',
    },
    save: {
      success: 'Changes saved for contract with the contract number {contractNo}.',
    },
    contractComment: {
      label: 'Comment',
    },
    afterFulfillmentText: {
      label: 'To complete after Contract fulfillment.',
    },
  },
  customerCommentDialog: {
    content: 'Comment - Customer',
    close: 'Close',
  },
  translatorCommentDialog: {
    content: 'Comment - Translator',
    close: 'Close',
  },
  invoiceContact: {
    title: 'Invoice Contact',
  },
  creditContact: {
    title: 'Credit Contact',
  },
  event: {
    details: {
      contractState: 'State',
      businessUnit: 'Business Unit',
      customer: 'Customer',
      translator: 'Translator',
      contractDate: 'Contract Date',
    },
  },
});
