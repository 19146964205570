export default {
  title: 'Credits',
  download: 'Download - {0}',
  dialog: {
    cancelCredit: {
      title: 'Cancel Credit',
      text: 'Do you really want to cancel the credits?',
      cancel: 'Cancel',
      close: 'Close',
    },
    setStateAdmin: {
      title: 'Set State - ADMIN-ACTION!',
      text: 'Please choose your target state.',
      close: 'Close',
    },
    showDownloadPdfZipCheck: {
      title: 'Hint for Accumulative Download',
      text: 'For following credits are not PDF files found:',
      downloadForced: 'download other files',
      close: 'Close',
    },
    warning: {
      title: 'Warning!',
      creditNotApproved: 'Only confirmed credits can be canceled.',
      close: 'Close',
    },
  },
  usageDialog: {
    usage: 'Comment',
    close: 'Close',
  },
  message: {
    reversalCreditCreated: 'Reversal credit created.',
  },
  enums: {
    CreditState: {
      CREATED: 'created',
      CANCELED: 'canceled',
      APPROVED: 'approved',
    },
  },
  list: {
    reversalTo: 'reversal to',
    setStateAdmin: {
      label: 'set state (ADMIN)',
    },
    cancelCredit: {
      label: 'Cancel Credit',
    },
    downloadAccumulativePDF: {
      label: 'Download-PDF (ZIP)',
    },
    actions: {
      sendEmailToTranslator: 'Send e-Mail to translator',
    },
    header: {
      creditReference: 'Credit Number',
      creditState: 'State',
      creditDate: 'Credit Date',
      translator: 'Translator',
      businessUnit: 'Business Unit',
      creditComment: 'Comment',
      isPaymentCompleted: 'Payment completed',
      downloadPDF: 'PDF',
    },
    delete: {
      title: 'Delete credit',
      message: 'Should the credit really be deleted?',
    },
    edit: {
      creditState: {
        fulfill: 'Fulfill credit',
      },
      sendEmail: {
        title: 'Send e-mail',
      },
    },
    filter: {
      creditReference: {
        label: 'Credit Number',
      },
      creditState: {
        label: 'State',
      },
      creditComment: {
        label: 'Comment',
      },
      contractNo: {
        label: 'Contract Number',
      },
      translator: {
        label: 'translator',
      },
      businessUnit: {
        label: 'Business Unit',
      },
      creditDateFrom: {
        label: 'Credit Date from',
      },
      creditDateUntil: {
        label: 'Credit Date until',
      },
      isPaymentCompleted: {
        label: 'Payment completed',
        true: 'Yes',
        false: 'No',
      },
      pdfCreatorAdmin: {
        label: 'Credit-PDF-Creator (Admin)',
      },
      pdfCreatorOfficeWorker: {
        label: 'Credit-PDF-Creator (Office Worker)',
      },
      isCreatedPDF: {
        label: 'Credit-PDF created',
        true: 'Yes',
        false: 'No',
      },
    },
  },
  form: {
    headerInfo: {
      label: 'Credit informations',
    },
    senderContact: {
      label: 'Sender',
    },
    receiverContact: {
      label: 'Receiver',
    },
    creditPositions: {
      label: 'Positions',
    },
    createPDF: {
      label: 'create PDF',
    },
    creditFiles: {
      label: 'Credit Download',
    },
    creditComment: {
      label: 'Comment',
    },
    creditDate: {
      label: 'Credit date',
    },
    creditReference: {
      label: 'Credit number',
    },
    taxId: {
      label: 'Tax ID',
    },
    accountingPeriodFrom: {
      label: 'Accounting period from',
    },
    accountingPeriodUntil: {
      label: 'Accounting period until',
    },
    bankAccountHolder: {
      label: 'Bank account holder',
    },
    bankName: {
      label: 'Bank name',
    },
    IBAN: {
      label: 'IBAN',
    },
    BIC: {
      label: 'BIC',
    },
    reference: {
      label: 'Reference',
    },
    notice: {
      label: 'Notice',
    },
    isPaymentCompleted: {
      label: 'Payment completed',
    },
    fileCreatedBy: {
      label: 'Creator: {0}',
    },
  },
  invoiceSummary: {
    header: 'Summary',
    subtotal: 'Subtotal',
    vat: 'Additionally VAT',
    total: 'Total incl. VAT',
  },
  positionList: {
    header: {
      operationReference: 'Operation',
      operationDate: 'Operation date',
      operationDescription: 'Kind of operation',
      count: 'Amount',
      countUnit: 'Unit (amount)',
      unitPriceNet: 'Price per unit (net)',
      unitPriceNetUnit: 'Unit(Price per unit)',
      totalPriceNet: 'Total price (net)',
      vat:  'Vat',
      vatRate: 'Vat Rate',
    },
    edit: {
      addCreditPosition: {
        label: 'Add Position',
      },
      operationReference: {
        label: 'Operation',
      },
      operationDate: {
        label: 'Operation date',
      },
      operationDescription: {
        label: 'Kind of operation',
      },
      count: {
        label: 'Amount',
      },
      countUnit: {
        label: 'Unit (amount)',
      },
      unitPriceNet: {
        label: 'Unit Price (net)',
      },
      unitPriceNetUnit: {
        label: 'Price per unit (net)',
      },
      totalPriceNet: {
        label: 'Total price (net)',
      },
      vat: {
        label: 'Vat',
      },
    },
  },
  email: {
    title: 'send e-mail',
    to: {
      label: 'to',
    },
    cc: {
      label: 'Copy to',
    },
    subject: {
      label: 'Subject',
    },
    body: {
      label: 'Content',
    },
    send: {
      label: 'Send',
      },
    },
    fileform: {
      title: 'File attachments',
      noData: 'No File attachments',
      filename: 'filename',
      uploadRequest: 'Please upload your attachment.',
      file: 'File',
  },
};
