export default {
  title: 'Office Worker',
  list: {
    header: {
      name: 'Name',
      email: 'E-mail',
    },
    delete: {
      title: 'Delete Office Worker',
      message: 'Do you really want to delete the user {fullName}?',
    },
  },
  form: {
    titleNew: 'New Office Worker',
    firstName: {
      label: 'First Name',
    },
    lastName: {
      label: 'Last Name',
    },
    email: {
      label: 'E-mail Address',
    },
    businessUnits: {
      label: 'Business Units',
    },
    isLocked: {
      label: 'User is locked',
    },
    userPermissions: {
      label: 'Permissions',
    },
    userPermissionsPreSet: {
      label: 'Preset:',
      OptionReadWrite: 'Read and Write',
      OptionReadOnly: 'Read Only',
      OptionNone: 'None',
    },
    sendActivationEmail: {
      label: 'Send activation e-mail',
    },
  },
};
