export default {
  title: 'Translator',
  list: {
    actions: {
      sendEmailToTranslator: 'Send e-mail to translator',
    },
    header: {
      name: 'Name',
      email: 'E-mail',
      mainPhoneNumber: 'Default Phone Number',
      costCenter: 'Cost center',
      translatorComment: 'Comment',
      languages: 'Languages',
      translatorContactLocation: 'Contact Location',
    },
    edit: {
      sendEmail: {
        title: 'Send e-mail',
      },
    },
    filter: {
      firstName: {
        label: 'Firstname',
      },
      lastName: {
        label: 'Lastname',
      },
      email: {
        label: 'E-Mail',
      },
      mainPhoneNumber: {
        label: 'Default Phone Number contains',
      },
      costCenter: {
        label: 'Cost center',
      },
      translatorContactLocation: {
        label: 'Contact Location contains',
      },
      translatorComment: {
        label: 'Comment contains',
      },
      languages: {
        label: 'Languages',
      },
      translator: {
        label: 'Translator',
      },
    },
    offeredServices: {
      INTERPRETER: 'I',
      TRANSLATOR: 'T',
    },
    delete: {
      title: 'Delete translator',
      message: 'Should the translator {fullName} really be deleted?',
    },
  },
  form: {
    titleNew: 'New translator',
    firstName: {
      label: 'Firstname',
    },
    lastName: {
      label: 'Lastname',
    },
    email: {
      label: 'E-mail address',
    },
    mainPhoneNumber: {
      label: 'Default Phone Number',
    },
    taxId: {
      label: 'Tax id',
    },
    costCenter: {
      label: 'Cost center',
    },
    bankAccountHolderInfoText: {
      label: 'Bank account informations for credit creation',
    },
    bankAccountHolder: {
      label: 'Bank account holder',
    },
    bankName: {
      label: 'Bank name',
    },
    IBAN: {
      label: 'IBAN',
    },
    BIC: {
      label: 'BIC',
    },
    vatExempt: {
      label: 'VAT-exempt',
    },
    translatorComment: {
      label: 'Comment',
    },
    isLocked: {
      label: 'User is locked',
    },
    isWebDavEnabled: {
      label: 'WebDAV contact',
    },
    sendActivationEmail: {
      label: 'Send activation e-mail',
    },
    languages: {
      label: 'Languages',
    },
    offeredServices: {
      label: 'Type Of Service',
      INTERPRETER: 'Interpreter',
      TRANSLATOR: 'Translator',
    },
  },
  commentDialog: {
    comment: 'Comment',
    close: 'Close',
  },
  costConfiguration: {
    title: 'Cost configuration',
    fixedRateNet: {
      label: 'flat rate (net)',
    },
    costConfigurationCycles: {
      label: 'Billing cycles',
      titleUnknown: 'New billing cycle',
      noData: 'No billing cycles defined',
      translatorCostConfigurationCycleDuplicates: 'Attention: Combination of customer groups and billing unit is not unique.',
      costConfigurationCycle: {
        customerGroup: {
          label: 'Customer group',
        },
        costConfigurationCycleType: {
          label: 'Billing unit',
        },
        firstCycleDuration: {
          label: 'First cycle',
        },
        firstCycleCostNet: {
          label: 'Costs for first cycle (net)',
        },
        followingCycleDuration: {
          label: 'Further cycles',
        },
        followingCycleCostNet: {
          label: 'Costs for further cycles (net)',
        },
      },
    },
  },
  creditContact: {
    title: 'Contact for credit',
  },
  email: {
    title: 'Send e-mail',
    to: {
      label: 'To',
    },
    cc: {
      label: 'Copy to',
    },
    subject: {
      label: 'Subject',
    },
    body: {
      label: 'Content',
    },
    send: {
      label: 'Send',
    },
  },
  fileform: {
    title: 'File attachments',
    noData: 'No File attachments',
    filename: 'filename',
    uploadRequest: 'Please upload your attachment.',
    file: 'File',
  },
};
