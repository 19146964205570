export default {
  title: 'User Account',
  account: {
    firstName: {
      label: 'First Name',
    },
    lastName: {
      label: 'Last Name',
    },
    email: {
      label: 'E-mail address',
    },
  },
  changePassword: {
    title: 'Password',
    passwordOld: {
      label: 'Current Password',
    },
    passwordNew: {
      label: 'New Password',
    },
    passwordNewConfirm: {
      label: 'Repeat New Password',
    },
    success: 'Your password has been changed.',
  },
  contact: {
    title: 'Contact info',
  },
  enums: {
    UserType: {
      CUSTOMER: 'Customer',
      TRANSLATOR: 'Translator',
      OFFICE_WORKER: 'Office worker',
      ADMIN: 'Admin',
      ACCOUNTANCY_FIRM: 'Accountancy Firm',
      UNKNOWN: 'unknown role',
    },
    UserPermission: {
      APPOINTMENT_READ: 'appointment read',
      APPOINTMENT_WRITE: 'appointment write',
      CONTRACT_READ: 'contract read',
      CONTRACT_WRITE: 'contract write',
      ACCOUNTING_READ: 'accounting read',
      ACCOUNTING_WRITE: 'accounting write',
      ACCOUNTING_DELETE: 'accounting delete',
      MASTER_DATA_READ: 'master data read',
      MASTER_DATA_WRITE: 'master data write',
      LANGUAGE_READ: 'language read',
      LANGUAGE_WRITE: 'language write',
      EMAIL_TEMPLATE_READ: 'E-Mail-template read',
      EMAIL_TEMPLATE_WRITE: 'E-Mail-template write',
      TRANSLATOR_READ: 'translator read',
      TRANSLATOR_WRITE: 'translator write',
      CUSTOMER_READ: 'customer read',
      CUSTOMER_WRITE: 'customer write',
      REPORT_READ: 'report read',
      REPORT_WRITE: 'report write',
    },
  },
};
